/* eslint-disable react/no-unused-state */
/* eslint-disable import/first */

// eslint-disable-next-line import/newline-after-import

// require('dotenv').config();

import React, { Component } from 'react';

import { Router, Switch } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';

import io from 'socket.io-client';

import 'react-toastify/dist/ReactToastify.css';

import history from './services/history';

import env from './helpers/envConfig';

import RouteMiddleware from './middlewares/RouteMiddleware';

// Views

import Detalhe from './views/Detalhe';

import Login from './views/Login';

import PainelContainer from './views/PainelContainer';

import Dashboard from './views/Dashboard';

import Import from './views/Import';

import Alertas from './views/Alertas';

import Usuarios from './views/Usuarios';

import NovoUsuario from './views/Usuarios/new';

import EditUsuario from './views/Usuarios/edit';

import Operacional from './views/Operacional';

import DetalheOperacional from './views/DetalheOperacional';

import AddTransitTime from './views/AddTransit';

import EditTransitTime from './views/EditTransit';

import TransitTimeList from './views/TransitTimeList';

import ListTipoJustificativa from './views/TipoJustificativa/ListTipoJustificativa';

import AddTipoJustificativa from './views/TipoJustificativa/AddTipoJustificativa';

import EditTipoJustificativa from './views/TipoJustificativa/EditTipoJustificativa';

import GrAlteradoTokenList from './views/GrAlteradoTokenList/index';

import Products from './views/Products';

import AlertRule from './views/AlertRule';

import UserHistory from './views/UserHistory';

// Components

import Menu from './views/components/Menu/index';

import Header from './views/components/Header/index';

// Images

// Css

import './css/main.scss';

import UserAuthenticated, * as authHelper from './helpers/authHelper';

const socket = io(env.URL_API);

class App extends Component {
  state = {
    username: '',

    useruuid: '',

    photo: '',

    admin: false,
  };

  async componentDidMount() {
    UserAuthenticated.Component = this;

    if (!(await authHelper.verifyLoggedUserIsValid())) {
      this.handleLogout();

      return;
    }

    const { name, uuid, admin } = authHelper.getTokenData().user;

    const photo = localStorage.getItem('USER_PHOTO')
      ? localStorage.getItem('USER_PHOTO')
      : '';

    if (name && uuid) {
      UserAuthenticated.isAuth = true;

      this.setState({
        username: name,

        photo,

        admin,
      });
    } else {
      UserAuthenticated.isAuth = false;

      this.setState({
        username: '',

        photo: '',

        admin: false,
      });
    }

    this.registerToSocket();
  }

  componentWillUnmount() {
    this.unregisterToSocket();
  }

  registerToSocket = () => {
    // socket.on('poItemAlert', newAlert => {

    // console.log('poItemAlert do WebSocket...', newAlert);

    // this.notifySucess(newAlert);

    // });

    socket.on('productsImport', () => {
      // console.log('poItemAlert do WebSocket...', newAlert);

      this.notifySucessText('Importação ATL concluída!');
    });

    socket.on('SapDowImport', () => {
      // console.log('poItemAlert do WebSocket...', newAlert);

      this.notifySucessText('Importação SAP DOW concluída!');
    });

    socket.on('SapCtvaImport', () => {
      // console.log('poItemAlert do WebSocket...', newAlert);

      this.notifySucessText('Importação SAP CTVA concluída!');
    });

    socket.on('SapDupontImport', () => {
      // console.log('poItemAlert do WebSocket...', newAlert);

      this.notifySucessText('Importação SAP Dupont concluída!');
    });

    socket.on('newAlertText', textMessage => {
      this.notifyErrorTextOnClick(textMessage, '/alertas');
    });
  };

  unregisterToSocket = () => {
    socket.removeListener('productsImport');

    socket.removeListener('SapDowImport');

    socket.removeListener('SapCtvaImport');

    socket.removeListener('SapDupontImport');

    socket.removeListener('newAlertText');
  };

  notifySucessText = message => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  /**

   * @argument message = mensagem texto puro do toast

   * @argument linkOnClick caso preenchido com uma rota, ao clicar no toast,

   * redireciona a página para a rota informada

   */

  notifyErrorTextOnClick = (message, linkOnClick) => {
    const routeLink = !linkOnClick || linkOnClick === '' ? null : linkOnClick;

    toast.error(message, {
      position: toast.POSITION.BOTTOM_RIGHT,

      onClick: () => {
        if (routeLink) history.push(routeLink);
      },
    });
  };

  notifyErrorText = message => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  handleLogout = () => {
    UserAuthenticated.userLogged = false;
  };

  render() {
    const { username, photo, admin } = this.state;

    const { isAuth } = UserAuthenticated;

    return (
      <div className="App">
        <Router history={history}>
          <Switch>
            <RouteMiddleware
              path="/GrAlterado/:userToken"
              title="Gr Alterado"
              component={GrAlteradoTokenList}
            />
            {!isAuth && (
              <RouteMiddleware path="*" title="Login" component={Login} />
            )}
            {isAuth ? (
              <div>
                <Menu
                  username={username}
                  empresa=""
                  photo={photo}
                  admin={admin}
                />

                <Header admin={admin} />

                <ToastContainer hideProgressBar autoClose={false} />
              </div>
            ) : null}
          </Switch>

          <Switch>
            {isAuth && (
              <RouteMiddleware
                title="Painel Gerencial Detalhe"
                path="/gerencial/:uuid"
                exact
                component={Detalhe}
              />
            )}

            {isAuth && (
              <RouteMiddleware
                title="Painel Gerencial"
                path="/gerencial"
                exact
                component={PainelContainer}
              />
            )}

            {isAuth && (
              <RouteMiddleware
                title="Dashboard"
                path="/dashboard"
                exact
                component={Dashboard}
              />
            )}

            {isAuth && (
              <RouteMiddleware
                title="Import"
                path="/import"
                exact
                component={Import}
              />
            )}

            {isAuth && (
              <RouteMiddleware
                title="Alertas"
                path="/alertas"
                exact
                component={Alertas}
              />
            )}

            {isAuth && admin && (
              <RouteMiddleware
                title="Usuários"
                path="/usuarios"
                exact
                component={Usuarios}
              />
            )}

            {isAuth && admin && (
              <RouteMiddleware
                title="Novo usuário"
                path="/usuarios/novo"
                exact
                component={NovoUsuario}
              />
            )}

            {isAuth && admin && (
              <RouteMiddleware
                title="Editar usuário"
                path="/usuarios/:uuid"
                exact
                component={EditUsuario}
              />
            )}

            {isAuth && (
              <RouteMiddleware
                title="Transit Time"
                path="/transit"
                exact
                component={TransitTimeList}
              />
            )}

            {isAuth && (
              <RouteMiddleware
                title="Novo Transit Time"
                path="/novo/transit/"
                exact
                component={AddTransitTime}
              />
            )}

            {isAuth && (
              <RouteMiddleware
                title="Editar Transit Time"
                path="/transit/:uuid"
                exact
                component={EditTransitTime}
                isPrivate
              />
            )}

            {isAuth && (
              <RouteMiddleware
                title="Operacional"
                path="/operacional"
                exact
                component={Operacional}
              />
            )}

            {isAuth && (
              <RouteMiddleware
                title="Operacional Detalhe"
                path="/operacional/detalhe/:uuid"
                exact
                component={DetalheOperacional}
              />
            )}

            {isAuth && (
              <RouteMiddleware
                title="Tipos de justificativa"
                path="/tipoJustificativa"
                exact
                component={ListTipoJustificativa}
              />
            )}

            {isAuth && (
              <RouteMiddleware
                title="Novo tipo de justificativa"
                path="/tipoJustificativa/novo"
                exact
                component={AddTipoJustificativa}
              />
            )}

            {isAuth && (
              <RouteMiddleware
                title="Editar tipo de justificativa"
                path="/tipoJustificativa/:uuid"
                exact
                component={EditTipoJustificativa}
              />
            )}

            {isAuth && (
              <RouteMiddleware
                title="Produtos"
                path="/produtos"
                exact
                component={Products}
              />
            )}

            {isAuth && (
              <RouteMiddleware
                title="Regras"
                path="/regras"
                exact
                component={AlertRule}
              />
            )}

            {isAuth && (
              <RouteMiddleware
                title="Auditoria"
                path="/auditoria"
                exact
                component={UserHistory}
              />
            )}

            {isAuth && (
              <RouteMiddleware
                title="Painel"
                path="/"
                exact
                component={PainelContainer}
              />
            )}
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
