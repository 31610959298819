import API from './api';

async function createNewHistory({ route, method, description, userUuid }) {
  const data = {
    route,
    method,
    userUuid,
    description,
  };

  try {
    await API.post('histories', { ...data });
  } catch (err) {
    console.log('Não foi possivel inserir auditoria ', err);
  }
}

export default createNewHistory;
