import React, { useState, useEffect } from 'react';

import moment from 'moment';

import api from '../../services/api';

import { List } from './styles';

function Products() {
  const [histories, setHistories] = useState();

  async function loadHistory() {
    await api.get('/histories').then(result => {
      setHistories(result.data);
    });
  }

  useEffect(() => {
    loadHistory();
  }, []);

  return (
    <div>
      <div className="center">
        <div className="page-header">
          <h1>Auditoria</h1>
        </div>

        <div>
          <List>
            <div className="header">
              <p>DATA</p>

              <p>AREA</p>

              <p>CATEGORIA</p>

              <p>USUÁRIO</p>
            </div>

            {histories && (
              <>
                {histories.map(item => (
                  <div className="item" key={item.uuid}>
                    <p>{moment(item.createdAt).format('DD/MM/YYYY HH:mm')}</p>

                    <p>{item.route}</p>

                    <p>{item.method}</p>

                    <p>{item.user.username}</p>
                  </div>
                ))}
              </>
            )}
          </List>
        </div>
      </div>
    </div>
  );
}

export default Products;
