/* eslint-disable camelcase */

import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { TableStyled, ItemTable, BarPercentTable, Tit } from './styles';

function Table({ data }) {
  const {
    total,

    port_entry_date,

    post_import_license_release_date,

    protocol_mapa_in26_date,

    data_do_registro_da_di,

    nf_date,

    transport_doc_delivery_date,

    loading_at_the_terminal,

    entrega_na_planta,

    gr_efetivo,
  } = data;

  const colspan = 2;

  const [days, setDays] = useState(0);

  let indexMedia = 0;

  let indexMeta = 0;

  useEffect(() => {
    if (total) {
      const medias = Math.round(
        parseFloat(port_entry_date.media) +
          parseFloat(post_import_license_release_date.media) +
          parseFloat(protocol_mapa_in26_date.media) +
          parseFloat(data_do_registro_da_di.media) +
          parseFloat(nf_date.media) +
          parseFloat(transport_doc_delivery_date.media) +
          parseFloat(loading_at_the_terminal.media) +
          parseFloat(entrega_na_planta.media) +
          parseFloat(gr_efetivo.media)
      );

      const metas =
        port_entry_date.meta +
        post_import_license_release_date.meta +
        protocol_mapa_in26_date.meta +
        data_do_registro_da_di.meta +
        nf_date.meta +
        transport_doc_delivery_date.meta +
        loading_at_the_terminal.meta +
        entrega_na_planta.meta +
        gr_efetivo.meta;

      setDays(medias > metas ? medias : metas);
    }
  }, [data]);

  function headerItems() {
    const items = new Array();

    for (let index = 0; index <= days; index += 1) {
      items.push(
        <th colSpan={index === 0 ? 1 : colspan} key={index}>
          {index}
        </th>
      );
    }

    return items;
  }

  function getRoundValues(value) {
    const values = `${value}`.split('.');

    let beforeDot = parseInt(values[0], 10);

    const afterDot = parseInt(values[1], 10);

    beforeDot *= colspan;

    if (afterDot >= 50) {
      beforeDot += 1;
    }

    return beforeDot;
  }

  function mediaItems(value, expected) {
    const items = new Array();

    let next = false;

    for (let index = 0; index <= days * colspan; ) {
      if (index === indexMedia && !next) {
        const roundValue = getRoundValues(value);

        items.push(
          <td colSpan={roundValue} key={indexMedia}>
            <ItemTable color={value > expected ? '#DB4C55' : '#02B99D'}>
              {value}
            </ItemTable>
          </td>
        );

        indexMedia += roundValue;

        next = true;

        index += roundValue || 1;
      } else {
        items.push(<td />);

        index += 1;
      }
    }

    return items;
  }

  function metaItems(value) {
    const items = new Array();

    let next = false;

    for (let index = 0; index <= days * colspan; ) {
      if (index === indexMeta && !next) {
        const roundValue = getRoundValues(value);

        items.push(
          <td colSpan={roundValue} key={indexMeta}>
            <ItemTable color="#3F51B5">{value}</ItemTable>
          </td>
        );

        indexMeta += roundValue;

        next = true;

        index += roundValue || 1;
      } else {
        items.push(<td />);

        index += 1;
      }
    }

    return items;
  }

  return (
    <>
      {total ? (
        <TableStyled>
          <tbody>
            <tr>
              <th />

              {headerItems()}
            </tr>

            <tr>
              <th rowSpan={2}>
                <Tit>ATA X PRES</Tit>{' '}
                <BarPercentTable
                  height="20"
                  percent={port_entry_date.delay}
                  color="y"
                  title={`${port_entry_date.delay}%`}
                />
              </th>

              {mediaItems(port_entry_date.media, port_entry_date.meta)}
            </tr>

            <tr>{metaItems(port_entry_date.meta)}</tr>

            <tr>
              <th rowSpan={2}>
                <Tit>PRES X DEFER</Tit>{' '}
                <BarPercentTable
                  height="20"
                  percent={post_import_license_release_date.delay}
                  color="y"
                  title={`${post_import_license_release_date.delay}%`}
                />
              </th>

              {mediaItems(
                post_import_license_release_date.media,

                post_import_license_release_date.meta
              )}
            </tr>

            <tr>{metaItems(protocol_mapa_in26_date.meta)}</tr>

            <tr>
              <th rowSpan={2}>
                <Tit>INSPECAO X DEFER</Tit>{' '}
                <BarPercentTable
                  height="20"
                  percent={protocol_mapa_in26_date.delay}
                  color="y"
                  title={`${protocol_mapa_in26_date.delay}%`}
                />
              </th>

              {mediaItems(
                protocol_mapa_in26_date.media,

                protocol_mapa_in26_date.meta
              )}
            </tr>

            <tr>{metaItems(protocol_mapa_in26_date.meta)}</tr>

            <tr>
              <th rowSpan={2}>
                <Tit>DEFER X REGIST</Tit>{' '}
                <BarPercentTable
                  height="20"
                  percent={data_do_registro_da_di.delay}
                  color="y"
                  title={`${data_do_registro_da_di.delay}%`}
                />
              </th>

              {mediaItems(
                data_do_registro_da_di.media,

                data_do_registro_da_di.meta
              )}
            </tr>

            <tr>{metaItems(data_do_registro_da_di.meta)}</tr>

            <tr>
              <th rowSpan={2}>
                <Tit>DESEMB X DANFE</Tit>{' '}
                <BarPercentTable
                  height="20"
                  percent={nf_date.delay}
                  color="y"
                  title={`${nf_date.delay}%`}
                />
              </th>

              {mediaItems(nf_date.media, nf_date.meta)}
            </tr>

            <tr>{metaItems(nf_date.meta)}</tr>

            <tr>
              <th rowSpan={2}>
                <Tit>DANFE X DOCS</Tit>{' '}
                <BarPercentTable
                  height="20"
                  percent={transport_doc_delivery_date.delay}
                  color="y"
                  title={`${transport_doc_delivery_date.delay}%`}
                />
              </th>

              {mediaItems(
                transport_doc_delivery_date.media,

                transport_doc_delivery_date.meta
              )}
            </tr>

            <tr>{metaItems(transport_doc_delivery_date.meta)}</tr>

            <tr>
              <th rowSpan={2}>
                <Tit>DOCS X SAIDA</Tit>{' '}
                <BarPercentTable
                  height="20"
                  percent={loading_at_the_terminal.delay}
                  color="y"
                  title={`${loading_at_the_terminal.delay}%`}
                />
              </th>

              {mediaItems(
                loading_at_the_terminal.media,

                loading_at_the_terminal.meta
              )}
            </tr>

            <tr>{metaItems(loading_at_the_terminal.meta)}</tr>

            <tr>
              <th rowSpan={2}>
                <Tit>SAIDA X DELIVERY</Tit>{' '}
                <BarPercentTable
                  height="20"
                  percent={entrega_na_planta.delay}
                  color="y"
                  title={`${entrega_na_planta.delay}%`}
                />
              </th>

              {mediaItems(entrega_na_planta.media, entrega_na_planta.meta)}
            </tr>

            <tr>{metaItems(entrega_na_planta.meta)}</tr>

            <tr>
              <th rowSpan={2}>
                <Tit>DELIVERY X GR</Tit>{' '}
                <BarPercentTable
                  height="20"
                  percent={gr_efetivo.delay}
                  color="y"
                  title={`${gr_efetivo.delay}%`}
                />
              </th>

              {mediaItems(gr_efetivo.media, gr_efetivo.meta)}
            </tr>

            <tr>{metaItems(gr_efetivo.meta)}</tr>
          </tbody>
        </TableStyled>
      ) : (
        <h1>Não há dados suficientes</h1>
      )}
    </>
  );
}

Table.propTypes = {
  data: PropTypes.shape({
    total: PropTypes.shape({
      media: PropTypes.string,
      meta: PropTypes.number,
      delay: PropTypes.number,
    }),

    port_entry_date: PropTypes.shape({
      media: PropTypes.string,
      meta: PropTypes.number,
      delay: PropTypes.number,
    }),

    post_import_license_release_date: PropTypes.shape({
      media: PropTypes.string,
      meta: PropTypes.number,
      delay: PropTypes.number,
    }),

    protocol_mapa_in26_date: PropTypes.shape({
      media: PropTypes.string,
      meta: PropTypes.number,
      delay: PropTypes.number,
    }),

    data_do_registro_da_di: PropTypes.shape({
      media: PropTypes.string,
      meta: PropTypes.number,
      delay: PropTypes.number,
    }),

    nf_date: PropTypes.shape({
      media: PropTypes.string,
      meta: PropTypes.number,
      delay: PropTypes.number,
    }),

    transport_doc_delivery_date: PropTypes.shape({
      media: PropTypes.string,
      meta: PropTypes.number,
      delay: PropTypes.number,
    }),

    loading_at_the_terminal: PropTypes.shape({
      media: PropTypes.string,
      meta: PropTypes.number,
      delay: PropTypes.number,
    }),

    entrega_na_planta: PropTypes.shape({
      media: PropTypes.string,
      meta: PropTypes.number,
      delay: PropTypes.number,
    }),

    gr_efetivo: PropTypes.shape({
      media: PropTypes.string,
      meta: PropTypes.number,
      delay: PropTypes.number,
    }),
  }),
};

Table.defaultProps = {
  data: {},
};

export default Table;
