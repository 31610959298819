/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';

const RouteMiddleware = ({ path, component: Component, title, ...rest }) => {
  const location = useLocation();

  useEffect(() => {
    // console.log('Acessou a rota: ', location.pathname);
    window.gtag('event', 'page_view', {
      page_title: title,
      page_path: location.pathname + location.search,
      page_location: window.location.href,
    });
  }, [location]);

  return (
    <Route {...rest} path={path} render={props => <Component {...props} />} />
  );
};

export default RouteMiddleware;
