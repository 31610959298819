import styled from 'styled-components';

const columnsSize = '2fr 3fr 3fr 4fr';

export const List = styled.div`
  p {
    font-size: 14px;

    line-height: 20px;
  }

  .btn.excluir {
    background-color: #ff5757;
  }

  .header {
    padding: 10px 20px;

    display: grid;

    align-items: center;

    grid-template-columns: ${columnsSize};

    grid-gap: 10px;

    @media (max-width: 620px) {
      grid-template-columns: 1fr;
    }
  }

  .item {
    background: #fff;

    border-radius: 6px;

    align-items: center;

    margin-bottom: 20px;

    padding: 10px 20px;

    transition: 0.2s;

    display: grid;

    grid-gap: 10px;

    grid-template-columns: ${columnsSize};

    height: auto;

    transition: 0.2s;

    &:hover {
      box-shadow: 1px 10px 10px rgba(0, 0, 0, 0.16);
    }

    &.critico {
      border-bottom: solid 6px #ff5757;

      box-sizing: border-box;

      background: #fff3f3;
    }
  }
`;
