import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { MdCancel } from 'react-icons/md';
import api from '../../services/api';
import createNewHistory from '../../services/userHistory';
import { getTokenData } from '../../helpers/authHelper';

import {
  List,
  Header,
  Item,
  ModalStyled,
  ModalContent,
  ModalHeader,
} from './styles';

function AlertRules() {
  const [alertRules, setAlertRules] = useState();
  const [rule, setRule] = useState();
  const [message, setMessage] = useState();

  async function loadAlertRules() {
    await api.get('/alert-rules').then(result => {
      setAlertRules(result.data);
    });

    const history = {
      route: 'ALERT_RULES',
      method: 'ACESSO',
      description: {},
      userUuid: getTokenData().user.uuid,
    };

    await createNewHistory({ ...history });
  }

  useEffect(() => {
    loadAlertRules();
  }, []);

  return (
    <div>
      <div className="center">
        <div className="page-header">
          <h1>Regras de ALerta</h1>
        </div>

        <ModalStyled
            isOpen={rule}
            contentLabel="ruleModal"
            ariaHideApp={false}
          >
            <ModalContent>
              <ModalHeader>
                <button
                  type="button"
                  onClick={() => setRule(null)}
                >
                  <MdCancel size={25} color="#F24B4B" />
                </button>
              </ModalHeader>
              {rule}
            </ModalContent>
          </ModalStyled>

          <ModalStyled
            isOpen={message}
            contentLabel="messageModal"
            ariaHideApp={false}
          >
            <ModalContent>
              <ModalHeader>
                <button
                  type="button"
                  onClick={() => setMessage(null)}
                >
                  <MdCancel size={25} color="#F24B4B" />
                </button>
              </ModalHeader>
              {message}
            </ModalContent>
          </ModalStyled>

        <div>
          <List>
            <Header>
              <p>Alerta</p>

              <p>Ultima Alteração</p>

              <p>Time</p>

              <p>Regra</p>

              <p>Mensagem</p>
            </Header>

            {alertRules && alertRules.map(item => (
              <Item
                className={item.alert_critical ? 'item critico' : 'item'}
                key={item.uuid}
              >
                <p>{item.type}</p>

                <p>{moment.utc(item.last_update).format('DD/MM/YYYY')}</p>

                <p>{item.team}</p>

                <p>
                  <button
                    type="button"
                    className="btn"
                    onClick={() => {
                      setRule(item.rule);
                      setMessage(null);
                    }}
                  >
                    Regra
                  </button>
                </p>

                <p>
                  <button
                    type="button"
                    className="btn"
                    onClick={() => {
                      setRule(item.message);
                      setMessage(null);
                    }}
                  >
                    Mensagem
                  </button>
                </p>
              </Item>
            ))}
          </List>
        </div>
      </div>
    </div>
  );
}

export default AlertRules;
