import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Images
import iconTitle from '../img/icons/title-gerencial.png';
import iconRgc from '../img/icons/rg-c.png';
import iconRgp from '../img/icons/rg-p.png';
import history from '../services/history';

// Components
import Loading from './components/Loading';
import { HeaderFilter } from './HeaderFilter';

class List extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    products: PropTypes.shape(PropTypes.any).isRequired,
    onFilter: PropTypes.func.isRequired,
  };

  state = {
    title: 'Painel Gerencial',
  };

  handleFormSubmit = params => {
    const { onFilter } = this.props;
    onFilter(params);
  };

  render() {
    const { isLoading, products } = this.props;
    const { title } = this.state;

    return (
      <div>
        <HeaderFilter
          onFilter={params => this.handleFormSubmit(params)}
          title={title}
          iconTitle={iconTitle}
        />

        <div className="center">
          <div className="content-regerencial">
            <div className="list-rege">
              <header className="headerlist">
                <div className="first">
                  <p>ID / Produto</p>
                  <p>GR Atual</p>
                </div>
              </header>

              {isLoading && <Loading />}
              {products.map(product => (
                <div
                  className={` ${product.product_id ? 'item urgent' : 'item'}`}
                  key={product.uuid}
                >
                  <div className="main-info">
                    <p className="emp">{product.consignee.split(' ')[0]}</p>
                    <p className="idpro">{product.product_id}</p>
                    <p className="namepro">
                      {product.product_description.substring(0, 20)}
                    </p>
                    {/* <p className="namepro">{product.name}</p> */}
                  </div>

                  <div className="info">
                    <div className="list-gra">
                      {product.items.map(po => (
                        <div
                          className={po.alert ? 'item-gra alert' : 'item-gra'}
                          key={po.uuid}
                        >
                          <p>
                            <strong
                              className="gr-actual"
                              onClick={() => {
                                this.storageBack();
                                history.push(
                                  `operacional/detalhe/${po.po_item_uuid}`
                                );
                              }}
                            >
                              <img src={iconRgc} alt="" />{' '}
                              {new Date(po.gr_actual).toLocaleDateString()}
                            </strong>
                          </p>
                          <p>
                            <img src={iconRgp} alt="" />{' '}
                            {po.total ? po.total.toLocaleString() : ''}
                          </p>
                        </div>
                      ))}
                    </div>
                    <div className="total-container">
                      <div className="item-total">
                        <p>
                          <strong>Total</strong>
                        </p>
                        <p>
                          <img src={iconRgp} alt="" />
                          {product.totalProduto
                            ? product.totalProduto.toLocaleString()
                            : ''}
                        </p>
                      </div>
                      <div className="item-total">
                        <p>
                          <strong>Total Recebido</strong>
                        </p>
                        <p>
                          <img src={iconRgp} alt="" />
                          {product.totalRecebido
                            ? product.totalRecebido.toLocaleString()
                            : '0'}
                        </p>
                      </div>
                      {product.totalProduto &&
                      product.totalRecebido &&
                      product.totalProduto - product.totalRecebido > 0 ? (
                        <div className="item-total">
                          <p>
                            <strong>Total a Receber</strong>
                          </p>
                          <p>
                            <img src={iconRgp} alt="" />
                            {(
                              product.totalProduto - product.totalRecebido
                            ).toLocaleString()}
                          </p>
                        </div>
                      ) : (
                        <div className="item-total" />
                      )}
                      {!product.totalRecebido ? (
                        <div className="item-total">
                          <p>
                            <strong>Total a Receber</strong>
                          </p>
                          <p>
                            <img src={iconRgp} alt="" />
                            {product.totalProduto.toLocaleString()}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default List;
