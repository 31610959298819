import styled from 'styled-components';
import { Form } from 'formik';
import imgloading from '../../img/load.gif';

export const Container = styled.div`
  padding: 30px;

  background: #fff;

  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);

  border-radius: 6px;

  margin-bottom: 40px;
`;

export const Title = styled.p`
  font-size: 18px;
  font-weight: 700;

  margin-bottom: 20px;

  span {
    font-size: 14px;

    font-weight: 600;

    display: block;

    margin: 5px 0;
  }
`;
export const TitleSM = styled(Title)`
  margin-bottom: 0;
`;

export const BoxGeral = styled.div`
  display: grid;

  grid-template-columns: 1.4fr 1fr 1fr;

  gap: 40px;
  margin-bottom: 40px;
  border-bottom: solid 1px #fff;
`;

export const BoxInfo = styled.div`
  display: flex;

  flex-direction: column;

  justify-content: center;
`;

export const BoxInfoCenter = styled(BoxInfo)`
  align-items: center;
`;

export const ItemBoxInfo = styled.div`
  margin-bottom: 20px;

  max-width: 300px;

  width: 100%;

  p {
    font-size: 12px;

    margin-bottom: 20px;

    big {
      font-size: 14px;

      font-weight: 700;

      display: block;

      margin-bottom: 5px;
    }
  }
`;

export const BarPercent = styled.div`
  width: 100%;

  height: ${props => props.height}px;

  border-radius: 6px;

  background: rgb(245, 245, 245);

  position: relative;

  &:after {
    content: '';

    position: absolute;

    width: ${props => props.percent}%;

    max-width: 100%;

    bottom: 0;

    height: ${props => props.height}px;

    border-radius: 6px;

    background: ${props =>
      // props.percent < 35

      //   ? '#FE6464'

      //   : props.percent < 60

      //   ? '#2684FA'

      //   : '#B7FF64'};

      // eslint-disable-next-line no-nested-ternary

      props.color === 'y' ? '#FF636B' : '#0281B9'};
  }

  strong {
    z-index: 1;
    position: absolute;
    top: 3px;
    left: ${props => props.percent > 10 ? props.percent - 10 : 0}%;

    font-weight: bold;
    color: #fff;
    font-size: 10px;
  }
`;

export const FormStyled = styled(Form)`
  width: 100%;
  margin-bottom: 40px;
  .btn {
    height: 50px;
    width: 100%;
    &.loading {
      float: none;
    }
  }
  label {
    display: block;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 10px;
    text-align: left;
  }
  input,
  select {
    width: 100%;
    height: 50px;
    border: solid 1px #fff;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 400;
    transition: all ease 0.3s;
    &:focus {
      border: solid 1px #c2c2c2;
    }
  }

  .nfs {
    input {
      margin-right: 10px;
    }
    label {
      display: flex;
      align-items: center;
      font-size: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const Loadingbtn = styled.div`
  width: 44px;
  height: 44px;
  background: url(${imgloading}) no-repeat center;
  display: none;
`;

export const TableStyled = styled.table`
  width: 100%;
  height: 100%;
  border-spacing: 0;
  margin-bottom: 20px;

  th {
    padding: 10px;
    font-weight: bold;
    font-size: 14px;
  }

  td {
    padding: 4px;
    background: #e4e4e4;
    border: 1px solid #ececec;
  }
`;

export const ItemTable = styled.div`
  width: 100%;
  height: 100%;
  padding: 4px;
  font-size: 12px;
  color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  background: ${props => props.color};
`;

export const Tit = styled.p`
  margin-bottom: 10px;
`;

export const BarPercentTable = styled.div`
  width: 100%;
  height: ${props => props.height}px;
  border-radius: 6px;
  background: ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.color === 'y' ? '#02B99D' : '#FE6464'};
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: ${props => props.percent}%;
    max-width: 100%;
    bottom: 0;
    height: ${props => props.height}px;
    border-radius: 6px;
    background: ${props =>
      // eslint-disable-next-line no-nested-ternary
      props.color === 'y' ? '#FE6464' : '#02B99D'};
  }
`;

export const ContentJustification = styled.div`
  padding: 40px 0;
  p {
    font-size: 16px;
  }
`;
export const JustifyTitle = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  img {
    margin-right: 10px;
  }
`;
export const AddJustification = styled.div``;
