import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Images
import iconTitle from '../../img/icons/title-gerencial.png';
import iconRgc from '../../img/icons/rg-c.png';
import iconRgp from '../../img/icons/rg-p.png';

// Components
import Loading from '../components/Loading';

import history from '../../services/history';
import { HeaderFilter } from '../HeaderFilter';

class List extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    products: PropTypes.shape(PropTypes.any).isRequired,
    onFilter: PropTypes.func.isRequired,
  };

  state = {
    title: 'Alteração de GR',
  };

  handleFormSubmit = params => {
    const { onFilter } = this.props;
    onFilter(params);
  };

  render() {
    const { isLoading, products } = this.props;
    const { title } = this.state;
    let total = 0;

    const adicionaTotal = valor => {
      total += valor;
    };

    const zeraTotal = () => {
      total = 0;
    };

    products.forEach(product => {
      const itensToOrder = [];

      product.pos.forEach(po => {
        const { order_reference } = po;

        po.po_items.forEach(po_item => {
          po_item.order_reference = order_reference;
          itensToOrder.push(po_item);
        });
      });

      const orderedItens = itensToOrder.sort((a, b) => {
        const dateComparison = a.gr_actual.localeCompare(b.gr_actual);

        if (dateComparison === 0) {
          const orderRefComparison = a.order_reference.localeCompare(b.order_reference);

          if (orderRefComparison === 0) {
            return a.item - b.item;
          }
          return orderRefComparison;
        }

        return dateComparison;
      });

      product.orderedItens = orderedItens;
    });

    return (
      <div>
        <HeaderFilter
          onFilter={params => this.handleFormSubmit(params)}
          title={title}
          iconTitle={iconTitle}
        />

        <div className="center">
          <div className="content-regerencial">
            <div className="list-alteracaogr">
              <header className="headerlistTitle">
                <p>ID / Produto</p>
                <p>PO</p>
                <p>GR Original</p>
                <p>GR Previsto</p>
                <p>GR Atual</p>
                <p>GR Efetivo</p>
                <p>Quantidade</p>
              </header>

              {isLoading ? (
                <Loading />
              ) : (
                products.map(product => (
                  <div className="item">
                    <div className="main-info">
                      <p className="emp">{product.consignee.split(' ')[0]}</p>
                      <p className="idpro">{product.product_id}</p>
                      <p className="namepro">{product.product_description}</p>
                    </div>
                    <div className="info">
                      <div className="list-gra">
                        {product.orderedItens.map(po => (
                          <div
                            className="item-gra link"
                            onClick={() => {
                              history.push(
                                `operacional/detalhe/${po.uuid}`
                              );
                            }}
                            key={`${po.order_reference}-${po.item}`}
                          >
                            <p>
                              <strong>
                                {po.order_reference}-{po.item}
                              </strong>
                            </p>
                            <p>
                              <img src={iconRgc} alt="" />
                              <strong>
                                {po.gr_original &&
                                  new Date(
                                    po.gr_original
                                  ).toLocaleDateString()}
                              </strong>
                            </p>
                            <p>
                              <img src={iconRgc} alt="" />
                              <strong>
                                {po.gr_expected &&
                                  new Date(
                                    po.gr_expected
                                  ).toLocaleDateString()}
                              </strong>
                            </p>
                            <p>
                              <img src={iconRgc} alt="" />
                              <strong>
                                {po.gr_actual &&
                                  new Date(
                                    po.gr_actual
                                  ).toLocaleDateString()}
                              </strong>
                            </p>
                            <p
                              className={
                                po.gr_effective &&
                                  new Date(po.gr_effective) >
                                  new Date(po.gr_actual)
                                  ? 'red'
                                  : 'blue'
                              }
                            >
                              <img src={iconRgc} alt="" />
                              <strong>
                                {po.gr_effective &&
                                  new Date(
                                    po.gr_effective
                                  ).toLocaleDateString()}
                              </strong>
                            </p>
                            <p>
                              <img src={iconRgp} alt="" />
                              <strong>
                                {po.qty && po.qty.toLocaleString()}
                              </strong>
                              {adicionaTotal(po.qty)}
                            </p>
                          </div>
                        )
                        )}
                      </div>
                      <div className="item-total">
                        <p>
                          <strong>Total</strong>
                        </p>
                        <p>
                          <img src={iconRgp} alt="" />
                          {total.toLocaleString()}
                          {zeraTotal()}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default List;
