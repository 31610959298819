/* eslint-disable camelcase */
/* eslint-disable react/button-has-type */
/*
ATA X PRES	0	1,03	0
PRES X DEFER	2	1,19	1,03
INSPECAO X DEFER	4	0	2,22
DEFER X REGIST	5	0,84	2,22
DESEMB X DANFE	6	0,53	3,06
DANFE X DOCS	7	0,22	3,59
DOCS X SAIDA	9	3,03	3,81
SAIDA X DELIVERY	12	0	6,84
DELIVERY X GR	12	0	6,84
*/


/*22/06/22
Atenção a descrição abaixo:
Foi realizado um ajuste de nomeclatura das empresas em urgencia
e não foi feito uma refatoração da nomeclatura para variaveis e objetos neste momento.

Corteva -> Corteva total
Dow     -> CTVA
DUPONT  -> corteva
*/ 

import React, { PureComponent } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Chart from 'react-google-charts';
import moment from 'moment';
import API from '../../services/api';

import {
  Container,
  Title,
  TitleSM,
  BoxGeral,
  FormStyled,
  Loadingbtn,
  BoxInfoCenter,
  ItemBoxInfo,
  BarPercent,
} from './styles';

// Components
import Table from './table';
import Justification from './Justification';
import Loading from '../components/Loading';

class TableDashboard extends PureComponent {
  state = {
    productsAll: {},
    corteva: {},
    dupont: {},
    dow: {},
    begin: moment()
      .subtract(1, 'months')
      .format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    product: null,
    cnpj: null,
    modal: null,
    critical: null,
    destination: null,
    loading: false,
  };

  componentDidMount() {
    this.getData();
    this.getProductsAll()
  }
  getProductsAll = async () =>{
    this.setState({
      loading: true,
    });
    try{
      const response = await API.get(`/products/all`);
      console.log('responseProduct', response.data)
      this.setState({
        productsAll: response.data,
        loading: false,
      })
    } catch(err){
      toast.error(err)
    };
  }
  getData = async () => {
    this.setState({
      loading: true,
    });

    const { begin, end } = this.state;

    try {
      const response = await API.get(
        `/poItems/delay/${begin || null}/${end ||
          null}/null/null/null/null/null`
      );

      this.setState({
        corteva: response.data.corteva,
        dupont: response.data.dupont,
        dow: response.data.dow,
        loading: false,
      });
    } catch (err) {
      toast.error(err);
    }
  };

  handleFilter = async (values, { setSubmitting }) => {
    try {
      if (values) {
        this.setState({
          begin: values.begin,
          end: values.end,
          product: values.product,
          cnpj: values.product ? null : values.cnpj,
          modal: values.product ? null : values.modal,
          critical: values.product ? null : values.critical,
          destination: values.destination,
        });
      }

      const {
        begin,
        end,
        product,
        cnpj,
        modal,
        critical,
        destination,
      } = this.state;

      const response = await API.get(
        `/poItems/delay/${begin || null}/${end || null}/${product ||
          null}/${cnpj || null}/${modal || null}/${critical ||
          null}/${destination || null}`
      );

      this.setState({
        corteva: response.data.corteva,
        dupont: response.data.dupont,
        dow: response.data.dow,
      });
      setSubmitting(false);
    } catch (err) {
      toast.error(err);
    }
  };

  render() {
    const { corteva, dupont, dow, loading, begin, end, productsAll } = this.state;

    return (
      <>
        <div className="center">
          {loading && <Loading />}
          {!loading && (
            <>
              <Formik
                enableReinitialize
                onSubmit={this.handleFilter}
                initialValues={{ begin, end }}
              >
                {props => (
                  <FormStyled>
                    <Grid>
                      <Row bottom="xs" between="xs">
                        <Col>
                          <label>Início GR Efetivo:</label>
                          <input
                            type="date"
                            name="begin"
                            value={props.values.begin}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            style={{
                              borderColor:
                                props.errors.begin &&
                                props.touched.begin &&
                                'red',
                            }}
                          />
                          {props.errors.begin && props.touched.begin && (
                            <div className="erro">{props.errors.begin}</div>
                          )}
                        </Col>
                        <Col>
                          <label>Fim GR Efetivo:</label>
                          <input
                            type="date"
                            name="end"
                            value={props.values.end}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            style={{
                              borderColor:
                                props.errors.end && props.touched.end && 'red',
                            }}
                          />
                          {props.errors.end && props.touched.end && (
                            <div className="erro">{props.errors.end}</div>
                          )}
                        </Col>

                        <Col sm={1.5} >
                          <label>Produto:</label>
                          <select
                            name="product"
                            value={props.values.product}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            style={{
                              borderColor:
                                props.errors.product &&
                                props.touched.product &&
                                'red',
                            }}
                          >
                            <option value="">Selecione...</option>
                            {
                              productsAll && productsAll[0] && productsAll.map(prod =>  
                                (
                                  <option value={prod.product_id}>
                                    {prod.product_description}
                                  </option>
                                )
                              )
                            }
                          </select>
                          {props.errors.product && props.touched.product && (
                            <div className="erro">{props.errors.product}</div>
                          )}
                        </Col>

                        <Col>
                          <label>CNPJ:</label>
                          <select
                            name="cnpj"
                            disabled={props.values.product}
                            value={props.values.cnpj}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            style={{
                              borderColor:
                                props.errors.cnpj &&
                                props.touched.cnpj &&
                                'red',
                            }}
                          >
                            <option value="">Selecione...</option>
                            <option value="61.064.929">CORTEVA</option>
                            <option value="47.180.625">CTVA</option>
                          </select>
                          {props.errors.cnpj && props.touched.cnpj && (
                            <div className="erro">{props.errors.cnpj}</div>
                          )}
                        </Col>
                        <Col>
                          <label>Modal:</label>
                          <select
                            name="modal"
                            disabled={props.values.product}
                            value={props.values.modal}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            style={{
                              borderColor:
                                props.errors.modal &&
                                props.touched.modal &&
                                'red',
                            }}
                          >
                            <option value="">Selecione...</option>
                            <option value="Air Import">AÉREO</option>
                            <option value="Ocean Import">MARÍTIMO</option>
                            <option value="Other Import">RODOVIÁRIO</option>
                          </select>
                          {props.errors.modal && props.touched.modal && (
                            <div className="erro">{props.errors.modal}</div>
                          )}
                        </Col>

                        <Col>
                          <label>Critico:</label>
                          <select
                            name="critical"
                            disabled={props.values.product}
                            value={props.values.critical}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            style={{
                              borderColor:
                                props.errors.critical &&
                                props.touched.critical &&
                                'red',
                            }}
                          >
                            <option value="">Selecione...</option>
                            <option value="YES">SIM</option>
                            <option value="NO">NÃO</option>
                          </select>
                          {props.errors.cnpj && props.touched.critical && (
                            <div className="erro">{props.errors.critical}</div>
                          )}
                        </Col>
                        <Col>
                          <label>Porto:</label>
                          <select
                            name="destination"
                            value={props.values.destination}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            style={{
                              borderColor:
                                props.errors.destination &&
                                props.touched.destination &&
                                'red',
                            }}
                          >
                            <option value="">Selecione...</option>
                            <option value="Curitiba">Curitiba</option>
                            <option value="Guarulhos">Guarulhos</option>
                            <option value="Santos">Santos</option>
                            <option value="Paranaguá">Paranaguá</option>
                            <option value="Uruguaiana">Uruguaiana</option>
                            <option value="Viracopos">Viracopos</option>
                          </select>
                          {props.errors.destination &&
                            props.touched.destination && (
                              <div className="erro">
                                {props.errors.destination}
                              </div>
                            )}
                        </Col>

                        <Col>
                          <button
                            variant="contained"
                            type="submit"
                            disabled={props.isSubmitting}
                            className={
                              props.isSubmitting ? 'btn loading' : 'btn'
                            }
                          >
                            <Loadingbtn className="loadingbtn" />
                            Filtrar
                          </button>
                        </Col>
                      </Row>
                    </Grid>
                  </FormStyled>
                )}
              </Formik>
              <Title>Performance</Title>
              <BoxGeral>
                <Container>
                  <TitleSM>
                    <small>CORTEVA TOTAL</small>
                  </TitleSM>
                  <div>
                    <Chart
                      width="100%"
                      height="300px"
                      chartType="PieChart"
                      loader={<div>Loading Chart</div>}
                      data={[
                        ['Performance Corteva Total', 'Performance'],

                        [
                          `Delay (${
                            corteva.total ? corteva.total.items_delay || 0 : 0
                          })`,
                          corteva.total ? corteva.total.delay || 0 : 0,
                        ],

                        [
                          `On Time (${
                            corteva.total
                              ? corteva.total.items_punctual || 0
                              : 0
                          })`,
                          corteva.total ? corteva.total.punctual || 100 : 100,
                        ],
                      ]}
                      options={{
                        width: '100%',
                        height: '100%',
                        pieHole: 0.5,
                        // pieSliceText: 'none',
                        legend: {
                          position: 'bottom',
                        },
                        isStacked: true,
                        slices: {
                          0: { color: '#DB4C55' },

                          1: { color: '#02B99D' },
                        },
                      }}
                    />
                  </div>
                  <BoxInfoCenter>
                    {
                      corteva.responsabilities &&
                      corteva.responsabilities[0] &&
                      corteva.responsabilities.map(item => (
                        <ItemBoxInfo>
                          <p>
                            <big>{item.title}</big>
                          </p>

                          <BarPercent
                            height="16"
                            percent={item.delay_percent}
                            color="y"
                          >
                            <strong>{item.delay_percent}%</strong>
                          </BarPercent>
                        </ItemBoxInfo>
                      ))
                    }
                  </BoxInfoCenter>
                </Container>

                <Container>
                  <TitleSM>
                    <small>CORTEVA</small>
                  </TitleSM>
                    <div>
                      <Chart
                        width="100%"
                        height="300px"
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={[
                          ['Performance Corteva', 'Performance'],

                          [
                            `Delay (${
                              dupont.total ? dupont.total.items_delay || 0 : 0
                            })`,
                            dupont.total ? dupont.total.delay || 0 : 0,
                          ],

                          [
                            `On Time (${
                              dupont.total ? dupont.total.items_punctual || 0 : 0
                            })`,
                            dupont.total ? dupont.total.punctual || 100 : 100,
                          ],
                        ]}
                        options={{
                          width: '100%',
                          height: '100%',
                          pieHole: 0.5,
                          legend: {
                            position: 'bottom',
                          },
                          isStacked: true,
                          slices: {
                            0: { color: '#DB4C55' },

                            1: { color: '#02B99D' },
                          },
                        }}
                      />
                    </div>
                    <BoxInfoCenter>
                      {
                        dupont.responsabilities &&
                        dupont.responsabilities[0] &&
                        dupont.responsabilities.map(item => (
                          <ItemBoxInfo>
                            <p>
                              <big>{item.title}</big>
                            </p>
                            <BarPercent
                              height="16"
                              percent={item.delay_percent}
                              color="y"
                            >
                              <strong>{item.delay_percent}%</strong>
                            </BarPercent>
                          </ItemBoxInfo>
                        ))
                      }
                    </BoxInfoCenter>
                </Container>

                <Container>
                  <TitleSM>
                    <small>CTVA</small>
                  </TitleSM>
                  <div>
                    <Chart
                      width="100%"
                      height="300px"
                      chartType="PieChart"
                      loader={<div>Loading Chart</div>}
                      data={[
                        ['Performance CTVA', 'Performance'],

                        [
                          `Delay (${dow.total ? dow.total.items_delay || 0 : 0})`,
                          dow.total ? dow.total.delay || 0 : 0,
                        ],

                        [
                          `On Time (${
                            dow.total ? dow.total.items_punctual || 0 : 0
                          })`,
                          dow.total ? dow.total.punctual || 100 : 100,
                        ],
                      ]}
                      options={{
                        width: '100%',
                        height: '100%',
                        pieHole: 0.5,

                        legend: {
                          position: 'bottom',
                        },
                        isStacked: true,
                        slices: {
                          0: { color: '#DB4C55' },
                          1: { color: '#02B99D' },
                        },
                      }}
                    />
                  </div>
                  <BoxInfoCenter>
                    {
                      dow.responsabilities &&
                      dow.responsabilities[0] &&
                      dow.responsabilities.map(item => (
                        <ItemBoxInfo>
                          <p>
                            <big>{item.title}</big>
                          </p>
                          <BarPercent
                            height="16"
                            percent={item.delay_percent}
                            color="y"
                          >
                            <strong>{item.delay_percent}%</strong>
                          </BarPercent>
                        </ItemBoxInfo>
                      ))
                    }
                  </BoxInfoCenter>
                </Container>
              </BoxGeral>
              <Title>
                Corteva - Aéreo <span>Tempo médio por etapa</span>
              </Title>

              <Table data={corteva} />
              <Justification />
            </>
          )}
        </div>
      </>
    );
  }
}

export default TableDashboard;
