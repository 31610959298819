import styled from 'styled-components';
import Modal from 'react-modal';

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Header = styled.li`
  width: 100%;
  padding: 10px 20px;
  display: grid;
  grid-template-columns: 40% 15% 15% 15% 15%;

  p {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Item = styled.li`
  width: 100%;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 20px;
  padding: 10px 20px;
  transition: 0.2s;
  display: grid;
  height: auto;
  grid-template-columns: 40% 15% 15% 15% 15%;
  &:hover {
    box-shadow: 1px 10px 10px rgba(0, 0, 0, 0.16);
  }

  p {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const BtnCadastrar = styled.button`
  border-radius: 6px;
  background: #1abc9c;
  border: solid 1px #1abc9c;
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
  transition: 0.2s;

  &:hover {
    background: transparent;
    border: solid 1px #1abc9c;
    color: #1abc9c;
  }
`;

export const ModalStyled = styled(Modal)`
  width: 100%;

  height: 100%;

  padding: 32px;

  background: rgba(0, 0, 0, 0.35);

  display: flex;

  align-items: center;

  justify-content: center;
`;

export const ModalHeader = styled.header`
  width: 100%;

  padding: 0 64px;

  margin-bottom: 32px;

  display: flex;

  align-items: center;

  justify-content: space-between;

  h1 {
    color: #333;

    font-size: 20px;

    font-weight: bold;
  }

  button {
    width: 25px;

    height: 25px;

    padding: 0;

    margin: 0;

    background: none;

    &.new {
      width: 150px;

      height: 40px;

      border-radius: 4px;

      padding: 0 15px;

      box-sizing: border-box;

      background: #59d99d;

      font-size: 16px;

      font-weight: bold;

      color: #fff;
    }
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  max-width: 500px;
  height: 100%;
  max-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 32px;
  overflow-y: auto;
  background: #eee;
  border-radius: 4px;

  font-size: 14px;
`;
