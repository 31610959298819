/* eslint-disable camelcase */
/* eslint-disable react/button-has-type */
import React, { PureComponent } from 'react';
import { Tabs, useTabState, usePanelState } from '@bumaga/tabs';
import createNewHistory from '../services/userHistory';
import { getTokenData } from '../helpers/authHelper';

// Images
import iconTitleDash from '../img/icons/title-dash.png';

import Performance from './Performance';

const Tab = ({ children }) => {
  const { isActive, onClick } = useTabState();
  return (
    <button className={isActive && 'active'} onClick={onClick}>
      {children}
    </button>
  );
};
const Panel = ({ children }) => {
  const isActive = usePanelState();

  return isActive ? <p>{children}</p> : null;
};

class Dashboard extends PureComponent {
  async componentDidMount() {
    const history = {
      route: 'DASHBOARD',
      method: 'ACESSO',
      description: {},
      userUuid: getTokenData().user.uuid,
    };
    localStorage.removeItem('filters');
    localStorage.removeItem('@Back');

    await createNewHistory({ ...history });
  }

  render() {
    return (
      <div>
        <div className="center">
          <Tabs>
            <div className="navgerencial">
              <Tab>Performance</Tab>

              <Tab>Antecipação / Alteração de GR</Tab>
            </div>
            <div className="page-header">
              <h1>
                <img src={iconTitleDash} alt="" />
                Dashboard
              </h1>
            </div>
            <Panel>
              <Performance />
            </Panel>
            <Panel>
              <iframe
                width="100%"
                height="700"
                src="https://app.powerbi.com/view?r=eyJrIjoiYjI4ZjZiODQtMjg2My00ZDRiLWEyYjEtZjEzOWFiNWMwYjIzIiwidCI6IjNhNTZkODhlLWUxNjgtNGNmZC1hMWM4LWVlOTVlMzVkZGI5ZiJ9"
                frameBorder="0"
                allowFullScreen="true"
                title="Dashboard2"
              />
            </Panel>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default Dashboard;
