import React, { useCallback } from 'react';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import propTypes from 'prop-types';

const HeaderFilter = ({ onFilter, title, iconTitle }) => {
  const [params, setParams] = React.useState(() => {
    const storage = window.localStorage.getItem('filters');

    if (storage) {
      const oldFilters = JSON.parse(storage);
      return {
        ...oldFilters,
        dataAte: oldFilters.dataAte ? new Date(oldFilters.dataAte) : '',
        dataDe: oldFilters.dataDe ? new Date(oldFilters.dataDe) : '',
        dupont: !!oldFilters.dupont,
        dow: !!oldFilters.dow,
      };
    }

    return {
      dupont: false,
      dow: false,
      produto: '',
      dataDe: '',
      dataAte: '',
      types: [],
      responsible: '',
      isFilterOpen: false,
    };
  });

  React.useEffect(() => {
    if (params.dataDe) {
      params.dataDe = new Date(params.dataDe).getTime();
    }

    if (params.dataAte) {
      params.dataAte = new Date(params.dataAte).getTime();
    }
    window.localStorage.setItem('filters', JSON.stringify(params));
  }, [params]);

  const handleFormSubit = useCallback(
    e => {
      e.preventDefault();

      const data = JSON.parse(JSON.stringify(params));

      if (params.dow) {
        data.dow = 'dow';
      }

      if (params.dupont) {
        data.dupont = 'dupont';
      }

      onFilter(data);
    },
    [params, onFilter]
  );

  const handleChangeState = React.useCallback((key, value) => {
    setParams(prev => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const handleBntFilter = React.useCallback(() => {
    setParams(prev => ({
      ...prev,
      isFilterOpen: !prev.isFilterOpen,
    }));
  }, []);

  const handleTypes = React.useCallback(
    e => {
      const { value, checked } = e.target;

      const { types: oldStateTypes } = params;
      const index = oldStateTypes.indexOf(value);
      if (index >= 0 && !checked) {
        oldStateTypes.splice(index, 1);
        setParams(prev => ({
          ...prev,
          types: oldStateTypes,
        }));
      } else if (checked) {
        setParams(prev => ({
          ...prev,
          types: [...oldStateTypes, value],
        }));
      }
    },
    [params]
  );

  return (
    <div className="center">
      <div className="page-header">
        <h1>
          <img src={iconTitle} alt="" />
          {title}
        </h1>

        <div className="last-wrap">
          <div
            className={`btn-filter-nfs ${params.isFilterOpen ? 'active' : ''}`}
            onClick={() => handleBntFilter()}
          >
            <div className="icon-filter">
              <span />
              <span />
              <span />
            </div>
            Filtrar
          </div>
        </div>
      </div>

      <div className={`filter-box ${params.isFilterOpen ? 'active' : ''}`}>
        <form onSubmit={handleFormSubit}>
          <div className="status">
            <div className="item">
              <label>Processo Status:</label>
              <div className="boxurgente">
                <label>
                  <input
                    type="checkbox"
                    value="1 - PRE-Embarque"
                    checked={!!params.types.includes('1 - PRE-Embarque')}
                    onChange={handleTypes}
                  />
                  Pré Embarque
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="2 - Em Transito"
                    checked={!!params.types.includes('2 - Em Transito')}
                    onChange={handleTypes}
                  />
                  Em trânsito
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="3 - Nacionalização"
                    checked={!!params.types.includes('3 - Nacionalização')}
                    onChange={handleTypes}
                  />
                  Nacionalização
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="4 - Faturamento"
                    checked={!!params.types.includes('4 - Faturamento')}
                    onChange={handleTypes}
                  />
                  Faturamento
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="5 - Processo Encerrado"
                    checked={!!params.types.includes('5 - Processo Encerrado')}
                    onChange={handleTypes}
                  />
                  Processo encerrado
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="8 - Close Financial Item"
                    checked={
                      !!params.types.includes('8 - Close Financial Item')
                    }
                    onChange={handleTypes}
                  />
                  Close financial item
                </label>
              </div>
            </div>
          </div>
          <div className="nfs">
            <label>
              <input
                type="checkbox"
                name=""
                id="dupont"
                onClick={() => handleChangeState('dupont', !params.dupont)}
                defaultChecked={params.dupont}
              />
              DUPONT
            </label>
            <label>
              <input
                type="checkbox"
                name=""
                id="dow"
                onClick={() => handleChangeState('dow', !params.dow)}
                defaultChecked={params.dow}
              />
              DOW
            </label>
          </div>
          <div className="item">
            <label>ID / Produto:</label>
            <input
              type="search"
              id="idproduto"
              onChange={e => handleChangeState('produto', e.target.value)}
              defaultValue={params.produto}
            />
          </div>
          <div className="item">
            <label>CSR ou Planejador:</label>
            <input
              type="search"
              id="idproduto"
              onChange={e => handleChangeState('responsible', e.target.value)}
              defaultValue={params.responsible}
            />
          </div>
          <div className="item">
            <label>Data início GR Efetivo:</label>
            <DatePicker
              locale="pt-BR"
              selected={params.dataDe}
              selectsStart
              onChange={date => handleChangeState('dataDe', date)}
              dataDe={params.dataDe}
              dataAte={params.dataAte}
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="item">
            <label>Data fim GR Efetivo:</label>
            <DatePicker
              locale="pt-BR"
              selected={params.dataAte}
              selectsEnd
              onChange={date => handleChangeState('dataAte', date)}
              dataDe={params.dataDe}
              dataAte={params.dataAte}
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="item">
            <label>Urgente:</label>
            <div className="boxurgente">
              <label>
                <input type="checkbox" name="" id="urgente-sim" />Y
              </label>
              <label>
                <input type="checkbox" name="" id="urgente-nao" />N
              </label>
            </div>
          </div>
          <div className="item">
            <label> &nbsp; </label>
            <button type="submit" className="btn">
              Filtrar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

HeaderFilter.propTypes = {
  onFilter: propTypes.func.isRequired,
  iconTitle: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
};

export { HeaderFilter };
