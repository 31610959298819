import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Loading from '../components/Loading';

import { List } from './styles';

function UserList(props) {
  return (
    <List>
      <div className="header">
        <p>Cadastro</p>

        <p>Nome</p>

        <p>Email</p>

        <p>Time</p>

        <p>Acesso</p>

        <p />

        <p />
      </div>
      {!props.isLoading ? (
        props.users.map(user => (
            <div className="item">
              <p>{moment.utc(user.createdAt).format('DD/MM/YYYY')}</p>
              <p>
                {`${user.name.substring(0, 18)}${
                user.name.length >= 18 ? '...' : ''}`}
              </p>
              <p>{user.username}</p>
              <p>{user.user_teams && user.user_teams[0] ? user.user_teams[user.user_teams.length - 1].team.teamName : ''}</p>
              <p>{user.admin ? 'Administrador' : 'Usuário'}</p>
              <Link to={`/usuarios/${user.uuid}`}>
                <button type="button" className="btn">
                  Alterar
                </button>
              </Link>
              {
                !user.dt_exclusao ? (
                  <button
                  type="button"
                  className="excluir"
                  onClick={() => props.onUserDelete(user.uuid)}
                  >
                    Bloquear
                  </button>
                ) : (
                  <button
                  type="button"
                  className="excluir"
                  onClick={() => props.onUserRestore(user.uuid)}
                  >
                    Desbloquear
                  </button>
                )
              }
            </div>
          )
        )
      ) : (
        <Loading />
      )}
    </List>
  );
}

export default UserList;
