import styled from 'styled-components';

const columnsSize = '1fr 3.5fr 0.5fr';

export const UserList = styled.div`
  p {
    font-size: 14px;
    line-height: 20px;
  }

  .btn.excluir {
    background-color: #ff5757;
  }
  .header {
    padding: 10px 20px;
    display: grid;
    align-items: center;
    grid-template-columns: ${columnsSize};
    grid-gap: 10px;
    @media (max-width: 620px) {
      grid-template-columns: 1fr;
    }
  }
  .item {
    background: #fff;
    border-radius: 6px;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px 20px;
    transition: 0.2s;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: ${columnsSize};
    height: auto;
    transition: 0.2s;
    &:hover {
      box-shadow: 1px 10px 10px rgba(0, 0, 0, 0.16);
    }
    &.critico {
      border-bottom: solid 6px #ff5757;
      box-sizing: border-box;
      background: #fff3f3;
    }
  }
`;

export const BtnCadastrar = styled.button`
  border-radius: 6px;
  background: #1abc9c;
  border: solid 1px #1abc9c;
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
  transition: 0.2s;

  &:hover {
    background: transparent;
    border: solid 1px #1abc9c;
    color: #1abc9c;
  }
`;
