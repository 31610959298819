import React, { useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';

import InputMask from 'react-input-mask';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Formik } from 'formik';
import { BtnMostrar, FormStyled } from './styles';
import swal from '@sweetalert/with-react';
import getCroppedImg from './cropImage';
import api from '../../services/api';
import history from '../../services/history';
import Loading from '../components/Loading';
import styled from 'styled-components';
import imgloading from '../../img/load.gif';

import iconBack from '../../img/icons/back.png';

const Loadingbtn = styled.div`
  width: 44px;
  height: 44px;
  background: url(${imgloading}) no-repeat center;
  display: none;
`;

// eslint-disable-next-line react/prefer-stateless-function

export default function NovoUsuario(props) {
  const [photo, setPhoto] = useState('');
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({});

  const [loading, setLoading] = useState(false);

  const [type, setType] = useState('password');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [aspect] = useState(4 / 4);

  const [teams, setTeams] = useState([]);
  const [teamuuid, setTeamuuid] = useState(null);

  useEffect(() => {
    async function loadTeams() {
      const response = await api.get(`/teams`);

      setTeams(response.data);
    }

    setLoading(true);
    loadTeams();
    setLoading(false);
  }, []);

  function getBase64(file, cb) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => cb(reader.result);
    reader.onerror = error => console.log('Error: ', error);
  }

  const handleSubmit = () => async (values, { setSubmitting, resetForm }) => {
    const croppedPhoto = await getCroppedImg(photo, croppedAreaPixels);

    const data = {
      user: {
        ...values,
        admin: values.admin || false,
        mailAlert: values.mailAlert || false,
        smsAlert: values.smsAlert || false,
        wppAlert: values.wppAlert || false,
        photo: croppedPhoto,
      },
    };

    const response = await api.post(`/users`, { ...data });

    if (response.status === 200) {
      if(teamuuid) {
        const { uuid } = response.data;

        const teamData = {
          useruuid: uuid,
          teamuuid,
        };
        const responseTeam = await api.post(`/userteams`, teamData);

        if (responseTeam.status === 200) {
          history.push('/usuarios');
        }
      }

      setSubmitting(false);
      resetForm();
      swal('Concluído', ' Usuário cadastrado com sucesso!', 'success');
      history.push('/usuarios');
    } else {
      swal('Erro', 'Não foi possível cadastrar usuário!', 'error');
    }
  };

  const handleValidate =
  ({
    name,
    username,
    password,
    phone,
  }) => {
    const errors = {};

    if (!name) errors.name = 'Nome Obrigatório';
    if (!username) errors.username = 'Email Obrigatório';
    if (!password) errors.password = 'Senha Obrigatório';
    if (!phone) errors.phone = 'Telefone Obrigatório';

    return errors;
  };

  function showHide(e) {
    e.preventDefault();
    e.stopPropagation();

    let newType;

    if (type === 'text') {
      newType = 'password';
    } else {
      newType = 'text';
    }

    setType(newType);
  }

  return (
    <div className="center">
      <div className="page-header">
        <h1>Novo Usuário</h1>
        <div className="last-wrap">
          <div className="btnvoltar" onClick={() => history.goBack()}>
            <img src={iconBack} alt="" />
            <p>Voltar</p>
          </div>
        </div>
      </div>
      {!loading ?
        (
          <Formik
          enableReinitialize
          initialValues={{
            admin: false,
            name: '',
            phone: '',
            username: '',
            password: '',
            mailAlert: false,
            smsAlert: false,
            wppAlert: false,
          }}
          validate={handleValidate}
          onSubmit={handleSubmit(props)}
          >
            {props => (
              <FormStyled>
                <div className="form">
                  <Grid>
                    <Row>
                      <Col xs={12}>
                        <div className="nfs item">
                          <label>
                            <input
                              type="checkbox"
                              name="admin"
                              value={props.values.admin}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                            />
                            Administrador
                          </label>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={6}>
                        <div className="item">
                          <label>Nome:</label>
                          <input
                            type="text"
                            name="name"
                            value={props.values.name}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            placeholder="Digite o seu nome"
                          />
                        </div>
                        {props.errors.name && props.touched.name && (
                          <span>{props.errors.name}</span>
                        )}
                      </Col>
                      <Col xs={12} md={6}>
                        <div className="item">
                          <label>Telefone:</label>
                          <InputMask
                            type="text"
                            name="phone"
                            value={props.values.phone}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            placeholder="Digite o seu telefone"
                            mask="(99) 99999-9999"
                            maskChar=" "
                          />
                        </div>
                        {props.errors.phone && props.touched.phone && (
                          <span>{props.errors.phone}</span>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <div className="item">
                          <label>E-mail:</label>
                          <input
                            type="email"
                            name="username"
                            value={props.values.username}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            placeholder="Digite o e-mail"
                          />
                        </div>
                        {props.errors.username && props.touched.username && (
                          <span>{props.errors.username}</span>
                        )}
                      </Col>
                      <Col xs={12} md={6}>
                        <div className="item">
                          <label>Senha:</label>
                          <input
                            type={type}
                            name="password"
                            value={props.values.password}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            placeholder="Digite a senha"
                          />
                          <BtnMostrar
                            type="button"
                            className={type === 'text' ? 'hide' : 'show'}
                            onClick={showHide}
                          />
                        </div>
                        {props.errors.password && props.touched.password && (
                          <span>{props.errors.password}</span>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <div className="item">
                          <label>Foto de perfil:</label>
                          <input
                            type="file"
                            id="imguser"
                            onChange={e => {
                              getBase64(e.target.files[0], result => {
                                setPhoto(result);
                              });
                              console.log(e.target.files[0]);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={3}>
                        <div className="nfs item">
                          <label>Notificações:</label>
                          <label>
                            <input
                              type="checkbox"
                              name="mailAlert"
                              value={props.values.mailAlert}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                            />
                            E-mail
                          </label>
                        </div>
                      </Col>
                      <Col xs={12} md={3}>
                        <div className="nfs item">
                          <label>&nbsp;</label>
                          <label>
                            <input
                              type="checkbox"
                              name="wppAlert"
                              value={props.values.wppAlert}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                            />
                            WhatsApp
                          </label>
                        </div>
                      </Col>
                      <Col xs={12} md={3}>
                        <div className="nfs item">
                          <label>&nbsp;</label>
                          <label>
                            <input
                              type="checkbox"
                              name="smsAlert"
                              value={props.values.smsAlert}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                            />
                            SMS
                          </label>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <div className="item">
                          <label>Time</label>
                            <select onChange={(e) => setTeamuuid(e.target.value)}>
                              <option value="" />
                              {teams && teams[0] && teams.map(item => (
                                <option selected={item.uuid === teamuuid ? true : false} id={item.uuid} value={item.uuid}>
                                  {item.teamName}
                                </option>
                              ))}
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <div className="item">
                          <button type="submit" disabled={props.isSubmitting} className={props.isSubmitting ? 'btn loading' : 'btn'}>
                            <Loadingbtn className="loadingbtn" />
                            Cadastrar
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Grid>
                </div>
                <div className="boxcroped">
                  <Cropper
                    image={photo}
                    crop={crop}
                    aspect={aspect}
                    onCropChange={newCrop => {
                      setCrop(newCrop);
                    }}
                    onCropComplete={(croppedArea, croppedAreaPixels) => {
                      setCroppedAreaPixels(croppedAreaPixels);
                    }}
                    // onZoomChange={onZoomChange}
                  />
                </div>
              </FormStyled>
            )}
          </Formik>
        ) :
        (
          <Loading />
        )
      }
    </div>
  );
}
