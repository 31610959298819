import OrderButtons from './Header';
import React from 'react';

export const columns = [
  {label: 'PO', accessor: 'order_reference', sortable: true},
  {label: 'Produto', accessor: 'product_id', sortable: true},
  {label: 'Descrição', accessor: 'product_description', sortable: true},
  {label: 'Qtd.', accessor: 'qty', sortable: true},
  {label: 'NCM', accessor: 'ncm', sortable: true},
  {label: 'ATA', accessor: 'ata_date', sortable: true},
  {label: 'GR Original', accessor: 'gr_original', sortable: true},
  {label: 'GR Atual', accessor: 'gr_actual', sortable: true},
  {label: 'Status', accessor: 'status', sortable: false},
];

export const OrderHeader = ({columns, updateSort}) => {

  return (
    <>
      <OrderButtons {...{columns, updateSort}}/>
    </>
  );
};
