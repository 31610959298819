import React, { useState } from "react";
import {MdKeyboardArrowRight, MdKeyboardArrowDown, MdKeyboardArrowUp} from 'react-icons/md';
import { HeaderStyled } from "./styles";

const arrowUp = <MdKeyboardArrowUp size = '15px'/>;
const arrowDown = <MdKeyboardArrowDown size = '15px'/>;
const arrowRight = <MdKeyboardArrowRight size = '15px'/>;

export let childOrder = '';
export let inOrder = '';

const OrderButtons = ({columns, updateSort}) => {
    const [sortField, setsortField] = useState('')
    const [order, setOrder] = useState('ASC')
  
    const handleChangeSort = (accessor) => {
      const sortOrder = accessor === sortField && order === 'ASC' ? 'DESC' : 'ASC';
      setOrder(sortOrder)
      setsortField(accessor)
      inOrder = sortOrder;
    }
    
    return(
      <HeaderStyled>
          {columns.map(({accessor, label, sortable})=>{
            const view = sortable ?
            accessor === sortField && order === 'ASC' ? arrowUp
            : accessor === sortField && order === 'DESC' ? arrowDown
            : arrowRight
            : '';

          return(
            <button key={accessor}
                onClick={sortable ? ()=>{handleChangeSort(accessor); childOrder = accessor; updateSort()}: null}
                className={accessor}>
            {view}{label}
            </button>
          );
          })}
      </HeaderStyled>
    )
  };

  export default OrderButtons;
