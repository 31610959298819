import React, { useState, useEffect } from 'react';

import api from '../../services/api';
import createNewHistory from '../../services/userHistory';
import { getTokenData } from '../../helpers/authHelper';

import { UserList } from './styles';

function Products() {
  const [product, setProduct] = useState();

  async function loadProducts() {
    await api.get('/products/all').then(result => {
      setProduct(result.data);
    });

    const history = {
      route: 'PRODUTOS',
      method: 'ACESSO',
      description: {},
      userUuid: getTokenData().user.uuid,
    };

    await createNewHistory({ ...history });
  }

  async function handleCriticalUpdate(uuid, alertCritical) {
    const data = {
      alert_critical: !alertCritical,
    };

    await api.put(`/products/${uuid}`, { ...data });

    const description = {
      productUuid: uuid,
      ...data,
    };

    const history = {
      route: 'PRODUTOS',
      method: 'ATUALIZAÇÃO',
      description,
      userUuid: getTokenData().user.uuid,
    };

    await createNewHistory({ ...history });

    loadProducts();
  }

  useEffect(() => {
    loadProducts();
  }, []);

  return (
    <div>
      <div className="center">
        <div className="page-header">
          <h1>Produtos</h1>
        </div>

        <div>
          <UserList>
            <div className="header">
              <p>Código</p>

              <p>Descrição</p>

              <p>Ação</p>
            </div>

            {product && (
              <>
                {product.map(item => (
                  <div
                    className={item.alert_critical ? 'item critico' : 'item'}
                    key={item.uuid}
                  >
                    <p>{item.product_id}</p>

                    <p>{item.product_description}</p>

                    <p>
                      <button
                        type="button"
                        onClick={() =>
                          handleCriticalUpdate(item.uuid, item.alert_critical)
                        }
                        className={item.alert_critical ? 'btn excluir' : 'btn'}
                      >
                        {!item.alert_critical ? 'Normal' : 'Crítico'}
                      </button>
                    </p>
                  </div>
                ))}
              </>
            )}
          </UserList>
        </div>
      </div>
    </div>
  );
}

export default Products;
