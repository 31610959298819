import React from 'react';

import * as S from './styles';

import justify from '../../img/icons/justify.svg';

function Justification() {
  return (
    <S.ContentJustification>
      <S.JustifyTitle>
        <img src={justify} alt="" />
        Justificativas
      </S.JustifyTitle>
      <p>
        <small>Nenhuma justificativa registrada...</small>
      </p>
    </S.ContentJustification>
  );
}

export default Justification;
