/* eslint-disable react/button-has-type */

import React, { Component } from 'react';

import ProductContainer from './ProductContainer';

import AlteracaoGrContainer from './AlteracaoGr/AlteracaoGrContainer';

import createNewHistory from '../services/userHistory';

import { getTokenData } from '../helpers/authHelper';

class PainelContainer extends Component {
  state = {
    gerencial: false,
    alteracaoGr: true,
  };

  async componentDidMount() {
    const back = localStorage.getItem('@Back') === 'Gerencial';
    this.setState({
      gerencial: back,
      alteracaoGr: !back,
    });

    const history = {
      route: 'PAINÉIS',
      method: 'ACESSO',
      description: {},
      userUuid: getTokenData().user.uuid,
    };

    await createNewHistory({ ...history });
  }

  toggleTab = tab => {
    this.setState(() => ({
      gerencial: tab === 'Gerencial',
      alteracaoGr: tab === 'AlteracaoGr',
    }));
    localStorage.setItem('@Back', tab);
  };

  render() {
    const { gerencial, alteracaoGr } = this.state;
    return (
      <div>
        <center>
          <div className="navgerencial">
            <button
              className={gerencial ? 'active' : ''}
              onClick={() => this.toggleTab('Gerencial')}
            >
              Gerencial
            </button>

            <button
              className={alteracaoGr ? 'active' : ''}
              onClick={() => this.toggleTab('AlteracaoGr')}
            >
              Alteração de GR
            </button>
          </div>
        </center>

        <div>
          {gerencial && <ProductContainer />}
          {alteracaoGr && <AlteracaoGrContainer />}
        </div>
      </div>
    );
  }
}

export default PainelContainer;
