import styled from 'styled-components';

export const HeaderStyled = styled.div`

  position: sticky;
  top: 0;
  background-color: #ececec;
  height: 37px;
  align-items: center;
  z-index: 1;
  display: grid;
  grid-template-columns: 2fr 2fr 4fr 2fr 2fr 2fr 2fr 2fr 2fr;
  padding-left: 20px;
  margin-bottom: 4px;

  .status{
    padding-left:15px;
  }

  @media (max-width: 740px){
    grid-template-columns: 2fr 2fr  2fr 2fr 1fr;
    padding-left: 30px;
    .product_id, .product_description, .qty, .ncm{
      display: none;
    }
    .status{
      padding-left:0px;
    }
  }

  button{
    padding: 0;
    text-align: left;
    height: 37px;
    display: flex;
    width: 100%;
    transition: 0.2s;
    border-bottom: 2px solid #ececec;
    font-size: 14px;
    align-items: center;
    background-color: #ececec;
    color: rgb(51, 51, 51);
  
    &:hover{
      color: #000;
      border-bottom: 2px solid #cecece;
    }
  }
`;


