import React, { Component } from 'react';
import { format } from 'date-fns';
import * as API from '../../helpers/apiHelper';
import AlteracaoGr from './AlteracaoGr';
import Detalhe from '../Detalhe';

class ProductContainer extends Component {
  state = {
    products: [],
    product: null,
    isLoading: false,
  };

  async componentDidMount() {
    const filtersPersist = JSON.parse(localStorage.getItem('filters') || {});

    this.handleFilter(filtersPersist);
  }

  async getProduct(uuid) {
    const { data: product } = await API.APIget(`products/${uuid}`);

    if (product) this.setState({ product });
  }

  handleRemoveProduct = () => {
    this.setState({
      product: null,
    });
  };

  async handleDetail(uuid) {
    await this.getProduct(uuid);
  }

  async handleFilter(filters) {
    this.setState(prev => ({ ...prev, isLoading: true }));
    if (filters.dataDe) {
      filters.dataDe = format(
        new Date(filters.dataDe),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      );
    }

    if (filters.dataAte) {
      filters.dataAte = format(
        new Date(filters.dataAte),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      );
    }
    const params = new URLSearchParams(filters);

    try {
      const response = await API.APIget(
        `products/alteracaoGr?${params.toString()}`
      );

      if (response.data) {
        this.setState(prev => ({ ...prev, products: response.data }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState(prev => ({ ...prev, isLoading: false }));
    }
  }

  render() {
    const { products, product, isLoading } = this.state;
    return (
      <div>
        {product ? (
          <Detalhe
            product={product}
            onRemoveProduct={this.handleRemoveProduct}
          />
        ) : (
          <AlteracaoGr
            products={products}
            isLoading={isLoading}
            onFilter={params => this.handleFilter(params)}
          />
        )}
      </div>
    );
  }
}

export default ProductContainer;
